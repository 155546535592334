@font-face {
  font-family: "handwritten";
  src: local("handwritten"), url(./fonts/JustBreathe.otf) format("opentype");
}

html,
body {
  font-family: sans-serif;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}

#root {
  width: 100%;
  height: 100%;
}
